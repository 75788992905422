import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import { Route } from 'react-router-dom';
import QuickPayGateway from '../route/QuickPayGateway';
import { withStoreRegex, HomePage } from 'Component/Router/Router.component';

const addQuickPayRoute = (member, context) => {
    const maxPosition = Math.max(
        ...member.map(route => route.position).filter(num => num < 1000)
    );
    return [
        ...member,
        {
            component: <Route path={ withStoreRegex('/quickpaygateway/payment/:resolution') } exact render={ (props) => <QuickPayGateway { ...props } /> } />,
            position: 1
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addQuickPayRoute
        }
    }
};
