import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from 'Component/ContentWrapper';
import CheckoutSuccess from 'Component/CheckoutSuccess';
import Loader from 'Component/Loader';
import Link from 'Component/Link';

import {
    PAYMENT_SUCCESS,
    PAYMENT_CANCEL
} from './QuickPayGateway.config';

/** @namespace Hoeks/QuickPay/Route/QuickPayGateway/Component */
export class QuickPayGateway extends PureComponent {

    renderTitle() {
        const { resolution } = this.props;
        const { title = '' } = this.renderMap[resolution];

        return (
            <h1 block="QuickPayGateway" elem="Title">
                { title }
            </h1>
        );
    }

    renderMap = {
        [PAYMENT_SUCCESS]: {
            title: __('Thank you for your purchase!'),
            render: this.renderSuccess.bind(this),
            action: this.updateSuccess.bind(this)
        },
        [PAYMENT_CANCEL]: {
            title: __('Cancelling order'),
            render: this.renderCancel.bind(this),
            action: this.updateCancel.bind(this)
        }
    };


    renderResolution(){
        const { resolution } = this.props;
        const { render } = this.renderMap[resolution];
        return render();
    }

    componentDidMount() {
        const { resolution } = this.props;
        const { action } = this.renderMap[resolution];
        return action();
    }

    updateSuccess(){
        const { order, loadOrder } = this.props;
        if (!order){
            loadOrder();
        }
    }

    renderSuccess(){
        const { order, isLoading } = this.props;
        if (isLoading){
            return <Loader isLoading={isLoading}/>;
        }
        return <CheckoutSuccess orderID={order.order_id}/>;
    }

    updateCancel(){
        const { order, cancelOrder } = this.props;
        if (!order){
            cancelOrder();
        }
    }

    renderCancel(){
        return (
            <div block="QuickPayGateway">
                <h3>{ __('Your order has been cancelled') }</h3>
                { this.renderButtons() }
            </div>
        );
    }

    renderButtons() {
        return (
            <div block="QuickPayGateway" elem="ButtonWrapper">
                <Link
                  block="Button"
                  mix={ { block: 'QuickPayGateway', elem: 'ContinueButton' } }
                  to="/"
                >
                    { __('Continue shopping') }
                </Link>
            </div>
        );
    }

    render(){
        return (
            <main block="QuickPayGateway">
                <ContentWrapper
                wrapperMix={ { block: 'QuickPayGateway', elem: 'Wrapper' } }
                label={ __('Payment page') }
                >
                    {this.renderTitle()}
                    {this.renderResolution()}
                </ContentWrapper>
            </main>
        );
    };
}

export default QuickPayGateway;