import { Field } from 'Util/Query';
import CheckoutQuery from 'Query/Checkout.query';

/** @namespace Hoeks/QuickPay/Query/QuickPay */
export class QuickPayQuery {
    getCreateQuickPayPaymentLinkMutation() {
        return new Field('s_createQuickPayPaymentLink')
            .addFieldList(this._getQuickPayLinkFields())
            .setAlias('quickpayLink');
    }

    getQuickPayPaymentSuccessQuery(){
        return new Field('s_quickPayPaymentSuccess')
            .addFieldList(this._getQuickPayPaymentSuccessFields())
            .setAlias('paymentData');
    }

    getQuickPayPaymentCancelMutation(){
        return new Field('s_quickPayPaymentCancel')
            .addFieldList(this._getQuickPayPaymentCancelFields())
            .setAlias('orderData');
    }


    _getQuickPayLinkFields() {
        return ['payment_link'];
    }

    _getQuickPayPaymentSuccessFields() {
        return ['order_id', CheckoutQuery._getTotalsField()]
    }

    _getQuickPayPaymentCancelFields() {
        return ['order_id'];
    }

    // getTotals(){
    //     const totals = CheckoutQuery.getTotalsField();
    //     console.log(query)
    //     // query._fieldList.items._fieldList.product.addField(ProductListQuery._getCategoriesField());
    //     console.log(query);
    //     return query;
    // }

}

export default new QuickPayQuery();
